<template>
    <div class="turnaround-upsell-table">
        <mercur-card>
            <slot></slot>
            <p class="text-center" data-test="TurnaroundUpsellTableEmptyNotice" v-if="!table.triggers">You need to add some triggers and upsells using plus buttons on sides</p>
            <table data-test="TurnaroundUpsellTable" class="turnaround-upsell-table__grid" :class="{'mb-20': editingUpsellTable}" v-else>
                <tbody>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td class="turnaround-upsell-table__top" v-for="(column, index) in table.triggers" :key="`top-${index}`">
                            <span class="turnaround-upsell-table__top-display" v-if="!editingUpsellTable">
                                {{ comparisonSign(column.comparison) }} {{ table.triggers[index].value }}
                            </span>
                            <div v-else class="position-relative">
                                <mercur-input v-model="table.triggers[index].value" :class="{'form-invalid': $v.table.triggers.$each[index].value.$invalid}">
                                    <template slot="prefix"><span class="turnaround-upsell-table__input-sign">{{ comparisonSign(column.comparison) }}</span></template>
                                    <template slot="note">
                                        <span class="form-error" v-if="!$v.table.triggers.$each[index].value.required">Required!</span>
                                        <span class="form-error" v-else-if="!$v.table.triggers.$each[index].value.numeric">Numeric!</span>
                                    </template>
                                </mercur-input>
                                <mercur-menu class="turnaround-upsell-table__menu">
                                    <mercur-button class="btn btn-icon btn-icon-sm"><i class="fas fa-chevron-down"></i></mercur-button>
                                    <div slot="dropdown">
                                        <label class="px-3 mb-1 mt-1 d-block">Range</label>
                                        <mercur-item><mercur-radio class="m-0" v-model="column.comparison" value="equal">Equal</mercur-radio></mercur-item>
                                        <mercur-item><mercur-radio class="m-0" v-model="column.comparison" value="greaterThan">Greater than</mercur-radio></mercur-item>
                                        <mercur-item><mercur-radio class="m-0" v-model="column.comparison" value="greaterThanOrEqual">Greater than or equal</mercur-radio></mercur-item>
                                        <mercur-item><mercur-radio class="m-0" v-model="column.comparison" value="lessThan">Less than</mercur-radio></mercur-item>
                                        <mercur-item><mercur-radio class="m-0" v-model="column.comparison" value="lessThanOrEqual">Less than or equal</mercur-radio></mercur-item>
                                        <hr class="m-0">
                                        <label class="px-3 mb-1 mt-2 d-block">Values</label>
                                        <mercur-item><mercur-radio class="m-0" v-model="column.margin" value="absolute">Exact</mercur-radio></mercur-item>
                                        <mercur-item><mercur-radio class="m-0" v-model="column.margin" value="percentage">Percentages</mercur-radio></mercur-item>
                                    </div>
                                </mercur-menu>
                            </div>
                        </td>
                    </tr>
                <tr v-for="(row, rowNumber) in table.upsells" :key="row.number">
                    <td>{{ row.number }}</td>
                    <td>
                        <input-transformer v-model="row.name" :disabled="!editingUpsellTable"></input-transformer>
                        <span class="table-error" v-if="!$v.table.upsells.$each[rowNumber].name.required">Name is required!</span>
                    </td>
                    <td class="position-relative" v-for="(trigger, index) in row.triggers" :key="`${row.name}-trigger-${index}`">
                        <input-transformer v-model="row.triggers[index]" :isPercentage="table.triggers[index].margin === 'percentage'" :disabled="!editingUpsellTable"></input-transformer>
                        <span class="table-error" v-if="!$v.table.upsells.$each[rowNumber].triggers.$each[index].required">Required!</span>
                        <span class="table-error" v-else-if="!$v.table.upsells.$each[rowNumber].triggers.$each[index].numeric">Numeric!</span>
                        <mercur-button data-test="TurnaroundUpsellTableDeleteColumn" v-if="editingUpsellTable && table.upsells && rowNumber === table.upsells.length - 1"
                            @click="deleteColumn(index)"
                            class="btn btn-icon delete-column"><i class="fas fa-minus-circle"></i>
                        </mercur-button>
                    </td>
                    <mercur-button v-if="editingUpsellTable" data-test="TurnaroundUpsellTableDeleteRow" class="btn btn-icon delete-row" @click="deleteRow(rowNumber)"><i class="fas fa-minus-circle"></i></mercur-button>
                </tr>
                </tbody>
            </table>
            <mercur-button v-if="table.triggers && editingUpsellTable" data-test="TurnaroundUpsellTableAddRightRow" class="btn btn-icon add add-right" @click="addColumn"><i class="fas fa-plus-circle"></i></mercur-button>
            <mercur-button v-if="editingUpsellTable" data-test="TurnaroundUpsellTableAddBottomRow" class="btn btn-icon add add-bottom" @click="addRow"><i class="fas fa-plus-circle"></i></mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import InputTransformer from './InputTransformer'
import { numeric, required } from 'vuelidate/lib/validators'

const emptyTrigger = {
    comparison: 'greaterThan',
    value: 0,
    margin: 'absolute',
}

const emptyUpsell = {
    number: 1,
    name: '',
    triggers: [0],
}
export default {
    name: 'TurnaroundUpsellTable',
    components: { InputTransformer },
    props: ['selectedTurnaroundUpsell', 'editingUpsellTable'],
    data () {
        return {
            table: this.selectedTurnaroundUpsell.turnaroundUpsellTable,
        }
    },
    watch: {
        selectedTurnaroundUpsell: {
            handler (value) {
                this.$set(this, 'table', value.turnaroundUpsellTable)
            },
            deep: true,
            immediate: true,
        },
    },
    validations: {
        table: {
            triggers: {
                $each: {
                    value: {
                        numeric,
                        required,
                    },
                },
            },
            upsells: {
                $each: {
                    name: {
                        required,
                    },
                    triggers: {
                        $each: {
                            required,
                            numeric,
                        },
                    },
                },
            },
        },
    },
    methods: {
        addColumn () {
            this.table.triggers.push(JSON.parse(JSON.stringify(emptyTrigger)))
            this.table.upsells.forEach(upsell => {
                upsell.triggers.push(0)
            })
        },
        addRow () {
            if (Array.isArray(this.table)) {
                this.table = {}
            }
            if (!this.table.triggers) {
                this.$set(this.table, 'triggers', [emptyTrigger])
            }

            if (!this.table.upsells) {
                this.$set(this.table, 'upsells', [emptyUpsell])
                return
            }

            this.table.upsells.push({
                number: this.table.upsells.length + 1,
                name: '',
                triggers: Array.apply(0, Array(this.table.triggers.length)).map(() => 0),
            })
        },
        deleteRow (index) {
            this.$delete(this.table.upsells, index)
            this.table.upsells = this.table.upsells.map((e, i) => { return { ...e, number: i + 1 } })
        },
        deleteColumn (index) {
            this.table.upsells.forEach((upsell, i) => {
                this.$delete(this.table.upsells[i].triggers, index)
            })
            this.$delete(this.table.triggers, index)
        },
        comparisonSign (comparison) {
            if (comparison === 'equal') {
                return '='
            }
            if (comparison === 'greaterThanOrEqual') {
                return '≥'
            }
            if (comparison === 'lessThanOrEqual') {
                return '≤'
            }
            if (comparison === 'greaterThan') {
                return '>'
            }
            return '<'
        },
    },
    created () {
        console.log(this.$props)
    },
}
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables.scss';
    .turnaround-upsell-table {
        .card {
            display: inline-block;
            min-width: 200px;
            min-height: 100px;
            margin-top: 20px;
            position: relative;
        }

        p {
            max-width: 400px;
        }

        &__menu {
            position: absolute;
            right: 5px;
            top: 6px;
            z-index: 2;
        }

        .add {
            position: absolute;
            &-right {
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-right: -60px;
            }

            &-bottom {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                margin-bottom: -55px;
            }
        }

        .delete-column {
            position: absolute;
            bottom: -40px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .delete-row {
            margin: 0;
        }

        &__grid {
            border-collapse: collapse;
            width: 100%;
            flex: 1;
            tr {

                td {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 8px 14px;
                    border: 4px solid white;
                    color: white;

                    &:nth-child(2) {
                        min-width: 120px;
                    }

                    &:nth-child(n+3) {
                        min-width: 87px;
                        text-align: right;
                    }
                }

            }

            tr:nth-child(5n + 2) td {
                background-color: $turnaround-upsell-green;
            }
            tr:nth-child(5n+ 3) td {
                background-color: $turnaround-upsell-orange;
            }
            tr:nth-child(5n + 4)  td {
                background-color: $turnaround-upsell-red;
            }
            tr:nth-child(5n + 5) td {
                background-color: $turnaround-upsell-purple;
            }
            tr:nth-child(5n + 1) td {
                background-color: $turnaround-upsell-blue;
            }

            tr:nth-child(1) td {
                background-color: white;
            }
        }

        &__top {
            position: relative;

            &-display {
                color: black;
            }
        }

        &__input-sign {
            padding-top: 5px;
            margin-right: 5px;
            font-size: .8em;
            color: black;
        }
    }

    .table-error {
        color: #ff1744;
        font-size: 10px;
    }
</style>
