<template>
    <mercur-tooltip data-test="inputTransformerWrapper" class="col-12">
        <template data-test="inputTransformerLabel" class="mb-2" v-if="!disabled && !isInput" slot="label">Click to change the value</template>
        <div class="input-transformer" @click="select">
            <div class="d-flex align-items-center" v-if="isInput && !disabled" >
                <input class="flex-grow-1" v-model="localValue" @keyup="checkKey" ref="input" :type="inputType">
                <mercur-tooltip>
                    <template slot="label">Stop editing</template>
                    <mercur-button data-test="inputTransformerBtn" @click="unselect" class="btn btn-icon btn-icon-sm text-white bg-transparent"><i class="fas fa-times"></i></mercur-button>
                </mercur-tooltip>
            </div>
            <span data-test="inputTransformerLabelPercent" v-else>{{ localValue }} <span v-if="isPercentage">%</span></span>
        </div>
    </mercur-tooltip>
</template>

<script>
export default {
    name: 'InputTransformer',
    props: {
        value: {
            required: true,
        },
        inputType: {
            default: 'text',
        },
        isPercentage: {
            default: false,
        },
        disabled: {
            default: false,
        },
    },
    data () {
        return {
            isInput: false,
        }
    },
    computed: {
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
    methods: {
        select () {
            if (this.disabled) {
                return
            }
            this.isInput = true
            this.$nextTick(() => {
                this.$refs.input.focus()
            })
        },
        unselect (e) {
            e.stopPropagation()
            this.isInput = false
        },
        checkKey (e) {
            if (e.keyCode === 13) {
                this.unselect()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables.scss';

.input-transformer {
    transition: all ease .2s;
    width: 100%;
    min-height: 22px;
    border-radius: 2px;
    input {
        min-height: 22px;
        padding: 0 5px;
        border: 1px solid $light-blue-color;
    }
}
</style>
